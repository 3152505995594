import React from "react"

import SiteLayout from "../../components/SiteLayout/SiteLayout"
import SEO from "../../components/SEO/SEO"
import styles from "./thankyou.module.css"

const ThankYouPage = () => (
  <SiteLayout>
    <SEO title="Thank you" />
    <div className={styles.container}>
      <h1>Thank You!</h1>
      <p>
        Your message is on its way to our inbox now..
        <br />
        We'll get back to you as soon as possible.
      </p>
      <p>
        Should you need further assistance, <br />
        please send us an <br />
        <a href="mailto:support@srponline.com.au">
          email here (support@srponline.com.au)
        </a>
        <br />
        and we'll get in touch with you as soon as possible.{" "}
      </p>
      <br />
      <br />
    </div>
  </SiteLayout>
)

export default ThankYouPage
